@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: theme('colors.gray.100');
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  height: 32px;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 10px;
  width: 8px;
}
*::-webkit-scrollbar-track {
  background-color: theme('colors.neutral.50');
}

*::-webkit-scrollbar-track:hover {
  background-color: theme('colors.neutral.50');
}

*::-webkit-scrollbar-track:active {
  background-color: theme('colors.neutral.50');
}

*::-webkit-scrollbar-thumb {
  background-color: theme('colors.neutral.350');
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: theme('colors.neutral.350');
}

*::-webkit-scrollbar-thumb:active {
  background-color: theme('colors.neutral.350');
}

.required-field {
  @apply after:ml-1 after:content-['*'] after:text-red-500;
}

.range-thumb::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--color-primary);
}

/* Hide scrollbar by default */
.sidebar-nav {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.sidebar-nav::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

/* Show scrollbar on hover */
.sidebar-nav:hover {
  scrollbar-width: thin; /* Firefox */
  -ms-overflow-style: auto; /* IE and Edge */
}

.sidebar-nav:hover::-webkit-scrollbar {
  display: block;
  width: 6px;
}

.sidebar-nav:hover::-webkit-scrollbar-track {
  background: transparent;
}

.sidebar-nav:hover::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 3px;
}

.sidebar-nav:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.7);
}

.action-box-selected {
  position: relative;
}

.action-box-selected::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  background: conic-gradient(
    from 23deg at 45.35% 67.43%,
    #ffa186 68.4625107049942deg,
    #4cffe7 187.19999313354492deg,
    #dc5dff 317.36459255218506deg
  );
  z-index: -1;
  background-size: 100% 100%;
  inset: 0px;
}

@keyframes wave-animation {
  0% {
    background-position: 0 bottom;
  }
  100% {
    background-position: -1600px bottom;
  }
}
